<template>
  <div class="">
    
    <e-departement  :departements="departements" />
    <b-card no-body class="">
      <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->

      <b-row class="px-2">
        <!-- Per Page -->

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1 "
        >
          <label>Entrées</label>

          <v-select
            v-model="state.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

          <b-button variant="primary" v-b-modal.modal-departement-created>
            <feather-icon icon="PlusIcon" class="mx-auto" />
            Nouveau departement
          </b-button>
        </b-col>

        <!-- Search -->

        <b-col cols="12" md="6" class="mt-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="state.filtre"
                class="d-inline-block mr-1"
                placeholder="Rechercher par : nom d'departement, lieu, entreprise..."
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>

      <q-loader-table
        :success="state.success"
        :empty="state.empty"
        :warring="state.warring"
      />

      <b-table
        hover
        responsive
        primary-key="id"
        :per-page="state.perPage"
        :current-page="state.currentPage"
        :items="departements"
        :fields="tableColumns"
        :filter="state.filter"
        show-empty
        empty-text="Aucun projet enregistré"
        class="bg-white  text-left"
        v-if="state.success === true"
      >

    <template #cell(created_at)="data">
      <div class="">
        <span> {{dateFormated(data.item.created)}} </span>
      </div>
    </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap py-1">
            
            <feather-icon
               @click="__Departement(data.item, 'edit')"
               v-b-modal.modal-departement
              icon="Edit3Icon"
              size="16"
              class="cursor-pointer"
            />

            <feather-icon
           
              icon="EyeIcon"
              class="mx-1 cursor-pointer"
              size="16"
            />

            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
     destoryDepartement(data.item)

            />

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
              disabled
              class="hidden"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item> -->
              <b-dropdown-item disabled>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50"> Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <!-- Les boutons de pagination -->

      <div class="mx-2 my-2 pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"></span>
          </b-col>

          <!-- Pagination -->

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="state.currentPage"
              :total-rows="departements.length"
              :per-page="state.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    {{ __NEXT }}
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/pages/request';
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import { reactive, ref, computed, onMounted } from '@vue/composition-api';
import { qDecrypted } from '@/services/qeCrypt';
import qDeviseUtils from '@/utils/qDeviseUtils';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
import moment from 'moment';
import {
  toast_destory,
  toast_error,
  toast_loading,
  toast_sucess,
  toast_update,
} from '@/utils/qToast';
import EDepartement from './eDepartement.vue';

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BLink,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    required,
    email,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    axios,
    QLoaderTable,
    EDepartement,
  },
  directives: {
    Ripple,
  },
  filters: {
    toSubtr(value) {
      if (!value) return '';
      value = value.toString();
      if (value.length > 15) {
        return value.substring(0, 15) + '...';
      } else {
        return value;
      }
    },
    toUpper(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  setup() {
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: '',
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const departements = ref([]);
    const tableColumns = reactive([
      {
        key: 'libelle',
        label: 'Libelle',
      },
      //   {
      //     key: 'type',
      //     label: 'Types',
      //   },
      {
        key: 'nombre_employe',
        label: 'Nombre d\'employer',
      },
      {
        key: 'email',
        label: 'email',
      },
      {
        key: 'description',
        label: 'description',
      },
      {
        key: 'created_at',
        label: "date de creation",
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ]);
    const perPageOptions = [30, 50, 100, 150, 250, 500];
    const GIVE = ref(false);
    const _departement = ref([]);
    const _whatOperation = ref('');


    onMounted(async() => {
      await fetchDepartements();
    })

    const __NEXT = computed(() => {
      if (GIVE.value === true) {
        departements.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
      return;
    });



    const __Departement = (data, status) => {
      _departement.value = data
    }

    const fetchDepartements = async () => {
      state.loading = true;
      const {data} = await axios.get(URL.DEPARTEMENT_LIST);
      console.log(data);
      if(data){
        departements.value = data[0]
        GIVE.value = true;
      }
    
      
    };



    const destoryDepartement = async (id) => {
         root
            .$swal({
               title: `Êtes vous sûr !?`,

               text: 'Aucun retour en arriére ne sera posible',

               icon: 'warning',

               showCancelButton: true,

               confirmButtonText: 'Oui',

               customClass: {
                  confirmButton: 'btn btn-primary',

                  cancelButton: 'btn btn-outline-danger ml-1',
               },

               buttonsStyling: false,
            })
            .then(async (response) => {
               if (response.isConfirmed === true) {
                  try {
                     const { data } = await axios.post(URL.DEPARTEMENT_DESTROY, {
                        id: id,
                     });
                     if (data) {
                        departements.value = departements.value.filter((art) => {
                           return art.id !== id;
                        });
                        
                        toast_destory(
                           root,
                           'success',
                           'top-right',
                           `Article supprimer avec succès !`
                        );
                     }
                  } catch (error) {
                     toast_error(
                        root,
                        'danger',
                        'top-right',
                        `Oups, un erreur est survenu veillez ressayer...`
                     );
                  }
               }
            });
      };



    const dateFormated = (date) => {
      return moment(date).format('DD-MM-YYYY');
    };

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem('entreprise_info');
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };
    return {
      state,
      perPageOptions,
      tableColumns,
      __NEXT,
      departements,
      formatter,
      GIVE,
      __Departement,
      _departement,
      destoryDepartement,
      _whatOperation,
      dateFormated
      
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
