<template>
   <!-- Modal pour ajouter une nouvelle taxe -->
   <b-modal
      id="modal-departement-created"
      cancel-variant="outline-secondary"
      ok-title="Créer"
      cancel-title="Annuler"
      centered
      :title=" 'Ajouter une neveau departement'
      "
      @ok="store"
     
   >
      <b-form>
         <!-- Libellé -->
         <b-form-group label="Libellé">
            <template #label>
               Libellé <span class="text-danger">*</span>
            </template>

            <b-form-input
               id="libelle"
               v-model="newDepartement.libelle"
               name="libelle"
               placeholder="Libellé du departement"
            />
            <span
               class="text-danger"
               style="font-size: 12px"
               v-if="errorInput.path === 'libelle'"
            >
               {{ errorInput.message }}
            </span>
         </b-form-group>

         <!-- Adresse Email -->
         <b-form-group label="Libellé">
            <template #label>
               Email
            </template>

            <b-form-input
               type="email"
               id="libelle"
               v-model="newDepartement.email"
               name="libelle"
               placeholder="Email du departement"
            />
            <span
               class="text-danger"
               style="font-size: 12px"
               v-if="errorInput.path === 'email'"
            >
               {{ errorInput.message }}
            </span>
         </b-form-group>

         <!-- Contact -->
         <b-form-group label="Zip Code" label-for="zip-code">
            <template #label>
               <span>Numéro de tel du departement</span>
            </template>
            <validation-provider
               #default="{ errors }"
               name="nom"
               rules="required"
            >
               <vue-tel-input
                  id="contact"
                  v-model="__phone"
                  @country-changed="changer"
                  @input="phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="000-000-000-000"
               />
            </validation-provider>
            <span
               class="text-danger"
               v-if="errorInput.path === 'contact'"
               style="font-size: 12px"
            >
               {{ errorInput.message }}
            </span>
         </b-form-group>

         <!-- Nombre d'employer -->
         <b-form-group label="Libellé">
            <template #label>
               Nombre d'emeployer
            </template>

            <b-form-input
               id="libelle"
               v-model="newDepartement.nombreEmployer"
               name="libelle"
               placeholder="Libellé du departement"
            />
            <span
               class="text-danger"
               style="font-size: 12px"
               v-if="errorInput.path === 'nombreEmployer'"
            >
               {{ errorInput.message }}
            </span>
         </b-form-group>

         <!-- Description du departement -->
         <b-form-group>
            <label for="taxeValue">Description </label>
            <b-form-textarea
               id="textarea"
               v-model="newDepartement.description"
               placeholder="Entrer les details de l'article ici"
               rows="5"
               max-rows="6"
            >
            </b-form-textarea>
         </b-form-group>
      </b-form>

      <template #modal-footer>
         <b-button
            :disabled="state.loading === true ? true : false"
            variant="primary"
            @click.stop.prevent="storeOrUpdate"
         >
            <span v-if="state.loading === false">Ajouter</span>
            <b-spinner
               v-if="state.loading === true"
               label="Spinning"
            ></b-spinner>
         </b-button>
      </template>
   </b-modal>
</template>

<script>
import { ref, reactive, onMounted, computed} from '@vue/composition-api';
import URL from '../../request';
import axios from 'axios';
import { toast_error, toast_sucess } from '@/utils/qToast';
export default {
   props: {
      departements: {
         type: Array,
         // required: true,
      },
   },
   setup(props, { root }) {
      const state = reactive({
         loading: false,
      });
      const errorInput = reactive({
         path: '',
         message: '',
      });
      const __phone = ref('')

      

      const newDepartement = reactive({
         libelle:  '',
         email: '',
         nombreEmployer: '',
         description: '',
         contact:  '',
      });

  

      const onclosed = () => {
         state.loading = false;
         errorInput.path = '';
         errorInput.message = '';
         newDepartement.libelle = '';
         newDepartement.email = '';
         newDepartement.nombreEmployer = '';
         newDepartement.description = '';
         newDepartement.contact = '';
         __phone.value = '';
        
      };

      const fetching = async (URL, departement) => {
         const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         const digit = /^\d+$/;
         try {
          
                state.loading = true;
               const { data } = await axios.post(URL, departement);
               console.log(data);
               state.loading = false;
               if (data) {
                  root.$bvModal.hide('modal-departement');
                  toast_sucess(root, 'success', 'top-right', 'Departement');
                  root.$emit('add-departement', data);
                   state.loading = false;
                  if (props.which === 'Ajouter') {
                     props.departements.unshift(data.departement);
                  } else {
                  }

                  onclosed();
               }
            
         } catch (error) {
            toast_error(root, 'error', 'top-right', 'Departement');
             state.loading = false;
            console.log(error);
         }
      };

      const store = async () => {
        
         const departement = {
            libelle: newDepartement.libelle,
            email: newDepartement.email,
            nombre_employe: newDepartement.nombreEmployer,
            description: newDepartement.description,
            contact: newDepartement.contact,
         };

         await fetching(URL.DEPARTEMENT_CREATE, departement);
         
      };

      const changer = (value) => {
         console.log(value);
      };

      const phone = (value) => {
         console.log(value);
         newDepartement.contact = value;
      };

      

      return {
         newDepartement,
         errorInput,
         state,
         changer,
         onclosed,
         store,
         __phone,
         phone,
          
      };
   },
};
</script>

<style lang="scss" scoped></style>
